<template>
    <header class="site-header">
        <!-- <div class="site-header__alert">
        </div> -->
        <div class="container">
            <div class="row">
                <div class="site-header__logo col-sm-4">
                    <router-link :to="{ name: 'home' }" aria-label="Quick Patient"><img src="@/assets/images/logo_quickpatient.svg" width="200" height="66" alt="Quick Patient"></router-link>
                </div>

                <nav class="site-header__navigation col-sm-8">
                    <router-link class="site-header__navigation-item" :to="{name: 'home', hash: '#for_practices'}" @click.native="setNav('practices')" :class="{'site-header__navigation-item--active':navActive==='for_practices'}">For Practices</router-link>
                    <router-link class="site-header__navigation-item" :to="{name: 'home', hash: '#for_patients'}"  @click.native="setNav('patients')" :class="{'site-header__navigation-item--active':navActive==='for_patients'}">For Patients</router-link>
                    <router-link class="site-header__navigation-item" :to="{name: 'home', hash: '#the_app'}"  @click.native="setNav('app')" :class="{'site-header__navigation-item--active':navActive==='the_app'}">App</router-link>
                    <router-link class="site-header__navigation-item" :to="{name: 'home', hash: '#faq'}" @click.native="setNav('faq')" :class="{'site-header__navigation-item--active':navActive==='faq'}">FAQ</router-link>
                    <!-- <a class="site-header__navigation-item" href="#">Support</a> -->
					<!-- <a class="site-header__navigation-item site-header__navigation-item--btn" href="#" @click.prevent="demoForm()" title="Request A Demo Form Popup">REQUEST DEMO</a> -->
                </nav>
            </div>
        </div>
    </header>
</template>

<script>
require('intersection-observer')
import { scrollorama, inViewport } from '@/lib/scrollorama'
import { bus } from '@/lib/bus'

export default {
	name: 'MainHeader',
	data: () => ({
		navItems: {
			patients: 'for_patients',
			practices: 'for_practices',
            faq: 'faq',
            app: 'the_app'
		},
		navActive: 'patients',
		navElements: {},
		watchScroll: false,
		observer: null,
	}),
	mounted() {
		scrollorama()

		for(const key in this.navItems) {
			const el = document.getElementById(this.navItems[key])
			if (el) {
				this.navElements[key] = el
			}
		}
		// this.watchScroll = true

		this.observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if (entry.intersectionRatio > 0) {
					this.navActive = entry.target.id
				}
			})
		})

		for (const key in this.navElements) {
			const el = this.navElements[key]
			this.observer.observe(el)
		}
	},
	methods: {
		setNav(item){
			this.watchScroll = false
			this.navActive = item
			setTimeout(() => {
				this.watchScroll = true
			}, 1000)
			return false
		},
		scrollWatch() {
			for (const key in this.navElements) {
				const el = this.navElements[key]
				if (!el) return
				if (inViewport(el, window.innerHeight / 2)) {
					this.navActive = key
				}
			}
		},
		demoForm() {
			bus.$emit('demoForm')
		},
	},
	watch: {
		navActive(newVal, oldVal) {
			if (newVal === oldVal) return
			if (this.watchScroll) return
			const el = document.getElementById(this.navItems[newVal])
			if (!el) return
			el.scrollIntoView({behavior: 'smooth'})
		},
		watchScroll(newVal) {
			if (newVal) {
				window.addEventListener('smoothScroll', this.scrollWatch)
			} else {
				window.removeEventListener('smoothScroll', this.scrollWatch)
			}
		},
	}
};
</script>

<style scoped lang="scss">
@mixin navbar()
{
    background-color: color_('black');
    border-radius: 0px;
    display: block;
    height: 2px;
    position: absolute;
    transition: transform $transition-cubic 350ms, background-color $transition-cubic 250ms;
    transform-origin: 50% 50%;
    width: 35px;
}


.site-header {
    @include gutter('padding', .75);
    // @include gutter('padding-top', .75);
    background-color: rgba(color_('white'), 0.85);
	backdrop-filter: blur(20px);
    box-shadow: $whiteframe2;
    display: flex;
    min-height: 52px;
    position: fixed;
    transition: all 300ms $easing-cubic;
    width: 100%;
    z-index: 10;

    @include media-breakpoint-down(sm) {
        background: rgba(color_('white'), 1);
        flex-wrap: wrap;
    }

    &--fixed {
        background: rgba(color_('white'), 100);
        box-shadow: $whiteframe2;
    }

    &__logo {
        @include make-col-ready();
        width: auto;
        //@include make-col(3);
        text-align: left;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            text-align: center;
        }

        .nav-button {

            &-wrapper {
                //border: 3px solid color_('grey');
                border-radius: 50%;
                cursor: pointer;
                height: 60px;
                position: fixed;
                right: 16px;
                top: 25px;
                z-index: 31;
                width: 60px;
                transform: translateZ(10px);
                display: none;

                @include media-breakpoint-down(sm) {
                    display: block;
                }
            }

            span {
                @include navbar();
                left: 30px;
                top: 30px;
                transform: translateY(0%) translateX(-50%);

                &:before,
                &:after {
                    @include navbar();
                    content: "";
                }
                &:before {
                    transform: translateY(-500%);
                }
                &:after {
                    transform: translateY(500%);
                }
            }

            &--open {
                span {
                    background-color: transparent;
                    transform: translateY(-50%) translateX(-50%) rotate(180deg);

                    &:before {
                        transform: rotate(45deg);
                    }

                    &:after {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }

    &__navigation {
        @include make-col-ready();
        //@include make-col(9);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-top: 1px solid rgba(255,255,255,0);

        @include media-breakpoint-down(sm) {
            display: none;
        }

        // @include media-breakpoint-down(sm) {
        //     @include make-col(12);
        //     flex-direction: column;
        //     //border-top: 1px solid rgba(255,255,255,0);
        //     @include gutter('margin-top', 0);
        //     max-height: 0;
        //     overflow: hidden;
        //     transition: all 200ms $easing-cubic;
        // }

        // &--open {
        //     max-height: 1000px;
        //     border-top: 1px solid #dee2e6;
        //     @include gutter('margin-top', .75);
        // }

        &-item {
            @include gutter('margin-left', .75);
            @include gutter('margin-right', .75);
            font-family: $headings-font-family;
            color: color_('text');
            font-weight: 500;
            position: relative;
            transition: all 150ms $easing-cubic;

            // @include media-breakpoint-down(sm) {
            //     width: 100%;
            //     border-bottom: 1px solid #dee2e6;
            //     @include gutter('padding', .75);
            // }

            &:after {
                content: "";
                width: 100%;
                height: 0;
                display: block;
                background: color_('primary');
                position: absolute;
                bottom: -5px;
                transition: all 150ms $easing-cubic;


                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }

            &:hover {
                text-decoration: none;
                color: color_('primary');

                &:after {
                    height: 1px;
                }
            }

            &--active {
                color: color_('primary');

                &:after {
                    height: 1px;
                }
            }

            &--btn {
                background-color: color_('primary');
                color: color_('white');
                border-radius: $border-radius;
                @include gutter('padding-left', 1);
                @include gutter('padding-right', 1);
                @include gutter('padding-top', .33);
                @include gutter('padding-bottom', .33);

                &:hover {
                    color: color_('white');
                    background-color: lighten(color_('primary'), 5);

                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    &__alert {
        width: 100%;
        background: color_('primary');
        color: color_('white');
        text-align: center;
        padding: 0px;
        @include gutter('margin-left', -.75);
        @include gutter('margin-right', -.75);
        position: absolute;
        top: 0;
        font-size: $font-size-sm * .9;
        display: none;

        a {
            color: color_('white');
        }
	}
}
</style>
