
export const scrollorama = () => {
	const event = new Event('smoothScroll')
	window.ticking = false
	window.addEventListener('scroll', () => {
		if (!window.ticking) {
			requestAnimationFrame(() => {
				window.dispatchEvent(event)
				window.ticking = false
			})
		}
		window.ticking = true
	})
}

export const inViewport = (el, offset = 0) => {
	const scroll = window.scrollY || window.pageYOffset
	const boundsTop = el.getBoundingClientRect().top + scroll

	const viewport = {
		top: scroll,
		bottom: scroll + window.innerHeight - offset,
	}

	const bounds = {
		top: boundsTop,
		bottom: boundsTop + el.clientHeight,
	}

	return (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) || (bounds.top <= viewport.bottom && bounds.top >= viewport.top)
}
