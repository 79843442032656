<template>
    <footer class="site-footer">
        <div class="container">
            <div class="row">
                <div class="site-footer__logo col-md-6">
                    <router-link :to="{name: 'home'}" href="#"><img src="@/assets/images/logo_quickpatient.svg" width="200" height="66" alt="Quick Patient"></router-link>
                </div>

                <div class="site-footer__legal col-md-6" role="contentinfo">
                    <router-link :to="{ name: 'privacy' }">Privacy Policy</router-link>
					| <router-link :to="{name: 'terms' }">Terms of Use</router-link>
					<!-- | <a href="#">Support (coming soon)</a> -->
					<br>
					Patent Pending |
                    &copy; Quick Patient, LLC. All rights reserved.
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'MainFooter',
};
</script>

<style scoped lang="scss">
.site-footer {
    display: flex;
    min-height: 52px;
    background: rgba(color_('white'), 1);
    width: 100%;
    @include gutter('padding', .75);
    @include gutter('padding-top', .75);
    border-top: 1px solid color_('background', 'medium');

    &__logo {
        @include make-col-ready();
        width: auto;
        text-align: left;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            text-align: center;
        }
    }

    &__legal {
        text-align: right;
        color: color_('text','xlight');
        font-size: $font-size-sm;
        margin-top: 5px;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            text-align: center;
        }

        a {
			color: color_('secondary');
            margin: 0 10px 5px;

            &:last-of-type {
                margin-right: 5px;
            }
        }
    }
}
</style>
